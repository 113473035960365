export const LETTERS = "abcdefghijklmnopqrstuvwxyz"
export const LETTERS_M = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
export const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
]
